/* latin */
@font-face {
  font-family: 'Khand';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Khand Light'), local('Khand-Light'), url('../fonts/khand-light.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Khand';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Khand Regular'), local('Khand-Regular'), url('../fonts/khand-regular.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Khand';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Khand Medium'), local('Khand-Medium'), url('../fonts/khand-medium.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Khand';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Khand Bold'), local('Khand-Bold'), url('../fonts/khand-bold.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  margin: 0;
  padding: 0;
  min-width:280px;
}

body {
  padding: 0;
  margin: 0;
  width: 100%;
}

h1 {
  margin-top: 10vh;
  text-align: center;
  &.small {
    margin-top: 80px;
    img {
      width: 20%;
    }
  }
}

h2 {
  font-family: Khand, sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: 4rem;
}

hr {
  width: 140px;
  color: #e7dad9;
}

.section-block {
  padding: 5vh;
}

.lazyload-wrapper {
  display: flex;
  justify-content: center;
}

.footer-suspense {
  height: 295px;
  width: 100%;
  padding: 2vw;
}

@media screen and (max-width: 640px) {
  .section-block {
    padding: 10vw 3vw;
  }
  h2 {
    font-size: 2rem;
  }
  .footer-suspense {
    height: 552px;
  }
}
